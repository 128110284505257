<template>
    <div class="industry_case_div">
        <div>
            <div class="case_banner">
                <img :src="imgSrc[0]"/>
            </div>
            <div class="case_all_div allContentWidth">
                <div class="tab_div">
                    <ul class="tab-title">
                        <li v-for="item in tabArr" :key="item.id" :class="{active:curActive==item.id}" @click="tabClickFun(item.id)">{{item.name}}</li>
                    </ul>
                </div>
                <div class="case_title_div">
                    <div class="case_title_img">
                        <!-- <img :src="imgSrc[1]"/> -->
                        <p>解决方案</p>
                        <div class="line_div_box"></div>
                    </div>
                </div>
                <div class="tab-content">
                    <div class="content-all-div">
                        <div class="content-left">
                            <p class="title_p">{{contentObj.title}}</p>
                            <div class="content_p">
                                <p v-for="cItem in contentObj.contentText" :key="cItem.id">{{cItem.texts}}</p>
                            </div>
                            <div class="content-bottom_div">
                                <div class="con_bottom_div" v-if="contentObj.bottom_content_left!==''">
                                    <div class="icon_div">
                                        <img :src="contentObj.icon_img_left"/>
                                    </div>
                                    <p class="p_size">{{contentObj.bottom_content_left}}</p>
                                    <div class="introduce_div">
                                        <p class="introduce_p" v-for="ul in contentObj.div_ul_left" :key="ul.id">
                                            <b class="circle_b"></b>
                                            <span @click="routeLinkFun(ul)">{{ul.value}}</span>
                                        </p>
                                    </div>
                                </div>
                                <div  class="con_bottom_div">
                                    <div class="icon_div">
                                        <img :src="contentObj.icon_img_right"/>
                                    </div>
                                    <p class="p_size">{{contentObj.bottom_content_right}}</p>
                                    <div class="introduce_div">
                                        <p class="introduce_p" v-for="ul in contentObj.div_ul_right" :key="ul.id">
                                            <b class="circle_b"></b>
                                            <span @click="routeLinkFun(ul)">{{ul.value}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content-right">
                            <img :src="contentObj.right_img"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import utils from '@/libs/utils';
export default {
    components: {},
    computed: {},
    mounted() {
        this.params();
        // this.tabClickFun(this.curActive);
    },
    watch: {},
    methods: {
        params(){
            var query = location.search.substr(1);
            query = query.split('&')
            var params = {};
            for (let i = 0; i < query.length; i++) {
                let q = query[i].split('=')
                if (q.length === 2) {
                    params[q[0]] = q[1]
                }
            }
            if((JSON.stringify(params) === '{}')===false){ //判断是否为空对象
                this.curActive = Number(params.tabActive);
            }else{
                this.curActive = 1;
            }
            this.tabClickFun(this.curActive);
        },
        tabClickFun(id){
            this.curActive = id;
            if(this.contentAll!==undefined||this.contentAll.length!==0){
                this.contentAll.forEach((item)=>{
                    if(id === item.key){
                       this.contentObj = item;
                    }
                })
            }
        },
        routeLinkFun(item){
            if([11,12].indexOf(item.id)===-1){
                let query = 'tabActive=' + item.id;
                this.$router.push(`/main_business`+ `?` + query );
            }else{
                utils.scrollTo();
            }
        }
    },
    data() {
        var imgSrc = [];
        imgSrc.push(require('../../assets/img/industry/case_banner.png'));//(0)
        imgSrc.push(require('../../assets/img/industry/case_title.png'));//(1)
        return {
            imgSrc: imgSrc,
            curActive: 1,
            tabArr:[
                {id: 1,name: '医药行业物流'},
                {id: 2,name: '零售业物流'},
                {id: 3,name: '家具行业物流'},
                {id: 4,name: '能源、机械设备物流'},
            ],
            contentObj:{},
            contentAll:[
                {
                    key:1,
                    title: '医药行业物流',
                    contentText:[
                        {id: 1,texts:'针对医药产品的特殊性及敏感性，药物运输过程中的各环节均需要进行严格的监督及管理。'},
                        {id: 2, texts: '2019年，新型冠状病毒对医疗健康行业影响巨大。现今，众多医药相关企业追寻更完善的管理方式，来匹配日益增长的需求与不可预测的供应。因此，我们需要完善的计划和流程来应对这其中可能出现的各类风险。与此同时，如何做好这类关键物资的供应链管理及其运输流程的把控极为重要。'},
                        {id: 3,texts: '2022年3月，经过一系列严格的审核，东志集团荣获IATA全球权威药品物流运输资质认证-CEIV Pharma认证证书。我们将严格遵守国际标准，对药品运输的各环节进行细致，规范的管理及把控，为您提供专业的医药物流解决方案。无论是非处方药物、疫苗和血清、医疗设备或保健品及其他医药相关产品，我们均竭尽所能，坚守高安全标准，严格遵守运输行业各项政策，为您提供全面，周到的物流解决方案。'}
                    ],
                    icon_img_left: require('../../assets/img/industry/jb_icon.png'),
                    icon_img_right: require('../../assets/img/industry/dq_icon.png'),
                    bottom_content_left: '作为您的医疗健康物流合作伙伴，我们的优势:',
                    bottom_content_right: '相关产品及服务:',
                    div_ul_left: [
                        {id: 1, value: '专业团队及优质客户服务'},
                        {id: 2, value: '符合GDP的合规流程'},
                        {id: 3, value: '专业设施设备'},
                        {id: 4, value: '实时货物追踪'},
                    ],
                    div_ul_right: [
                        {id: 2, value: '空运'},
                        {id: 3, value: '合约物流'},
                        {id: 1, value: '海运'},
                    ],
                    right_img: require('../../assets/img/industry/content_yywl.png')
                },
                {
                    key:2,
                    title: '零售业物流',
                    contentText:[
                        {id: 1, texts: '东志集团为零售行业提供专业及具有成本效益的物流服务，确保能迅速运送大批量货物，以及有效率地管理供应链上的生产商和供应商。我们可提供极具竞争力的零售行业解决方案，全力协助你的产品以理想的状态、效率和成本竞争力进入市场。'},
                        {id: 2,texts: '作为一个综合物流服务供应商，东志集团经营可提供全面的运输网络，包括陆运、空运、海运、铁路和合约物流。我们可为客户提供可靠、准时的配送服务。另外，我们将提供全程追踪服务，让您随时了解库存水平，配送路线及运输过程货运信息。'}
                    ],
                    icon_img_left: require('../../assets/img/industry/jb_icon.png'),
                    icon_img_right: require('../../assets/img/industry/dq_icon.png'),
                    bottom_content_left: '作为您的零售行业合作伙伴，我们的优势:',
                    bottom_content_right: '相关产品及服务:',
                    div_ul_left: [
                        {id: 1, value: '专业的团队及优质的客户服务'},
                        {id: 2, value: '自有仓储体系及订单管理系统'},
                        {id: 3, value: '全球领先的货运追踪和库存管理系统'},
                        {id: 4, value: '定制化高效解决方案'},
                        {id: 5, value: '灵活变通的应急处理能力'},
                    ],
                    div_ul_right: [
                        {id: 2, value: '空运'},
                        {id: 1, value: '海运'},
                        {id: 3, value: '合约物流'},
                        {id: 4, value: '铁路'},
                    ],
                    right_img: require('../../assets/img/industry/content_lsywl.png')
                },
                {
                    key:3,
                    title: '家具行业物流',
                    contentText:[
                        {id: 1, texts: '东志集团深耕货代行业二十余年，与国内外各大家具行业客户缔结了长期战略合作关系。无论航运市场情况如何变化，我们均能为您提供高效的仓库分拣服务，稳定的舱位支持，以及实时追踪货物的定位，充分满足您的不同需求。'},
                    ],
                    icon_img_left: require('../../assets/img/industry/jb_icon.png'),
                    icon_img_right: require('../../assets/img/industry/dq_icon.png'),
                    bottom_content_left: '作为您的家具行业战略合作伙伴，我们的优势:',
                    bottom_content_right: '相关产品及服务:',
                    div_ul_left: [
                        {id: 1, value: '熏蒸服务'},
                        {id: 2, value: '行业经验分享'},
                        {id: 3, value: '采购供应商资源'},
                        {id: 4, value: '具有竞争力的运输成本'},
                    ],
                    div_ul_right: [
                        {id: 1, value: '海运'},
                        {id: 3, value: '合约物流'},
                        {id: 4, value: '铁路'},
                    ],
                    right_img: require('../../assets/img/industry/content_jjhywl.png')
                },
                {
                    key:4,
                    title: '能源、机械设备物流',
                    contentText:[
                        {id: 1, texts: '对于汽车、太阳能设备、大件设备、重型设备、精密仪器等大型特殊货物来说，运输时效和费用预算均有严格要求。东志集团深知大件设备跨境运输的困难性，以及运输各环节可能存在的各类风险。'},
                        {id:2, texts:'针对不同类型、不同尺寸的货物，我们将为您提供合理的运输方案，并对全流程进行严谨规划和持续监督，积极应对各种风险。无论在流程中的哪一环节，我们将应用市场上先进的解决方案和技术，确保您的货物准时、安全的抵达目的地。'},
                        {id: 3,texts: '东志集团凭借着多年运输经验与可靠稳定的供应商网络，与多家知名散杂船、滚装船和集装箱船东保持良好的合作关系，运输航线可覆盖东南亚、中东、拉美、地中海及澳洲等主要机械设备的进口国。可根据客户的不同需求，为您提供专业的定制化物流解决方案。'}
                    ],
                    icon_img_left: require('../../assets/img/industry/jb_icon.png'),
                    icon_img_right: require('../../assets/img/industry/dq_icon.png'),
                    bottom_content_left: '',
                    bottom_content_right: '相关产品及服务:',
                    div_ul_left: [],
                    div_ul_right: [
                        {id: 1, value: '海运'},
                        {id: 3, value: '合约物流'},
                        {id: 4, value: '铁路'},
                        {id: 11, value: '大件运输'},
                        {id: 12, value: '危险品运输'},
                    ],
                    right_img: require('../../assets/img/industry/content_nyjxwl1.png')
                }
            ],
        }
    }
}
</script>
<style lang="less">
.industry_case_div{
    .case_banner{
        width:100%;
        img{
            width:100%;
            height:100%;
        }
    }
    .case_all_div{
        //tab页
        .tab_div{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 107px;
            margin-top: -60px;
            .tab-title{
                display: flex;
                align-items: center;
                height: 107px;
                background: #fff;
                box-shadow:  0px 1px 17px 1px rgba(0,0,0,0.1000);
                li{
                    text-align: center;
                    margin: 0 50px;
                    cursor: pointer;
                    font-size: 20px;
                    color: #232931;
                    position: relative;
                }
                li:hover::after{
                    width: 100%;
                }
                li::after{
                    transition: all 0.5s;
                    position: absolute;
                    content: "";
                    bottom: 0px;
                    left: 0px;
                    width: 0px;
                    height: 3px;
                    background: #2200ba;
                    // top: 40px;
                }
            }
            .tab-title .active {
                font-weight: 700;
                &::after{
                    width: 100%;
                }
			}
        }
        //标题
        .case_title_div{
            display: flex;
            justify-content: center;
            .case_title_img{
                margin: 50px 0 30px 0;
                font-size: 30px;
                font-weight: 700;
                .line_div_box{
                    background: #2200ba;
                    width: 30px;
                    height: 4px;
                    margin: auto;
                }
                img{
                    width:100%;
                    height:100%;
                }
            }
        }
        //tab页内容
        .tab-content{
            margin: 20px 0 80px 0;
            .content-all-div{
                display: flex;
                .content-left{
                    padding-right: 20px;
                    .title_p{
                        font-size: 28px;
                        font-weight: 600;
                        color: #404040;
                        line-height: 45px;
                    }
                    .content_p{
                        font-size: 12px;
                        font-weight: 400;
                        color: #656565;
                        line-height: 30px;
                        p{
                            margin: 20px 0 20px 0;
                        }
                    }
                    .content-bottom_div{
                        display: flex;
                        justify-content: space-between;
                        padding:50px 50px 0 0;
                        .con_bottom_div{
                            .icon_div{
                                height:37px;
                                width:37px;
                                margin-bottom: 10px;
                                img{
                                    width:100%;
                                    height:100%;
                                }
                            }
                            .p_size{
                                font-size: 16px;
                                font-weight: 600;
                                color: #404040;
                                line-height: 45px;
                            }
                            .introduce_div{
                                .introduce_p{
                                    .circle_b{
                                        width: 6px;
                                        height: 6px;
                                        background: #2200ba;
                                        display: inline-block;
                                        border-radius: 3px;
                                        margin-right: 10px;
                                    }
                                    span{
                                        font-size: 14px;
                                        color: #727272;
                                        line-height: 28px;
                                    }
                                    &:hover{
                                        cursor: pointer;
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }
                }
                // .content-right{
                //     width:538px;
                //     height: 522px;
                //     img{
                //         width:100%;
                //         height:100%;
                //     }
                // }
            }
        }
    }
}
@media screen and (max-width:900px){
    .industry_case_div{
        .case_all_div{
            //tab页
            .tab_div{
                margin-top: 0;
                height: 70px;
                .tab-title{
                    height: 70px;
                    li{
                        margin: 0 20px;
                        font-size: 12px;
                    }
                }
            }
            //tab页内容
            .tab-content{
                .content-all-div{
                    flex-direction: column;
                    .content-left{
                        padding: 0 20px;
                        .content-bottom_div{
                            flex-direction: column;
                            .con_bottom_div{
                                margin:0 0 20px 0;
                            }
                        }
                    }
                    .content-right{
                        width: 100%;
                        img{
                            width:100%;
                        }
                    }
                }
            }

        }
    }
}
</style>
